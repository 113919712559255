// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-algemene-voorwaarden-js": () => import("./../../../src/pages/algemene-voorwaarden.js" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-js" */),
  "component---src-pages-boek-js": () => import("./../../../src/pages/boek.js" /* webpackChunkName: "component---src-pages-boek-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cursussen-lezingen-js": () => import("./../../../src/pages/cursussen-lezingen.js" /* webpackChunkName: "component---src-pages-cursussen-lezingen-js" */),
  "component---src-pages-disclaimer-js": () => import("./../../../src/pages/disclaimer.js" /* webpackChunkName: "component---src-pages-disclaimer-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kennis-en-downloads-js": () => import("./../../../src/pages/kennis-en-downloads.js" /* webpackChunkName: "component---src-pages-kennis-en-downloads-js" */),
  "component---src-pages-privacyverklaring-js": () => import("./../../../src/pages/privacyverklaring.js" /* webpackChunkName: "component---src-pages-privacyverklaring-js" */),
  "component---src-pages-publicaties-js": () => import("./../../../src/pages/publicaties.js" /* webpackChunkName: "component---src-pages-publicaties-js" */),
  "component---src-pages-wat-is-kavelruil-js": () => import("./../../../src/pages/wat-is-kavelruil.js" /* webpackChunkName: "component---src-pages-wat-is-kavelruil-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

